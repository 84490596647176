<template>
  <div class="personnelDashboard__members">
    <div v-if="!personnel.length" class="personnelDashboard__members-empty js-members-empty">no members</div>
    <router-link
      v-for="item of personnel"
      :key="item.id"
      class="personnelDashboard__members-item js-members-item"
      to="#"
    >
      <div
        v-if="item.photo"
        class="personnelDashboard__members-item-avatar"
        :style="{backgroundImage: `url(${item.photo})`}"
      >
        <div class="personnelDashboard__members-item-avatar-back">
          <SvgIcon name="zoom"/>
        </div>
      </div>
      <div v-else class="personnelDashboard__members-item-avatar">
        <SvgIcon name="aeroplane2"/>
        <div class="personnelDashboard__members-item-avatar-back">
          <SvgIcon name="zoom"/>
        </div>
      </div>
      <div class="personnelDashboard__members-item-info">
        <div class="personnelDashboard__members-item-name">{{item.name}}</div>
        <div class="personnelDashboard__members-item-descr">{{item.description}}</div>
      </div>
      <div class="personnelDashboard__members-item-contacts">
        <div class="personnelDashboard__members-item-phone">{{item.phone}}</div>
        <div class="personnelDashboard__members-item-email">{{item.email}}</div>
      </div>
      <div class="personnelDashboard__members-item-buttons">
        <div class="personnelDashboard__members-item-profile">
          <SvgIcon name="user3"/>
        </div>
        <div class="personnelDashboard__members-item-remove js-removeMemebers" @click="showModal(item.id)">
          <SvgIcon name="close"/>
        </div>
      </div>
    </router-link>
    <QuestionPopup
      :name="'questionPopupMembers'"
      :title="'The member will be removed'"
      :text="'Are you sure?'"
      @remove="remove"
    />
  </div>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import QuestionPopup from '@/components/common/modals/QuestionPopup'
  import './PersonnelDashboardMembers.styl'

  export default {
    props: ['personnel'],
    name: 'PersonnelDashboardMembers',
    data: () => ({
      currentMember: null,
    }),
    methods: {
      showModal (id) {
        this.$modal.show('questionPopupMembers')
        this.currentMember = id
      },
      async remove () {
        await this.$store.dispatch('deleteTeamMember', this.currentMember)
      },
    },
    components: {
      SvgIcon,
      QuestionPopup,
    },
  }
</script>
