<template>
  <div class="personnelDashboard">
    <vue-scroll :ops="ops">
      <div class="personnelDashboard__left">
        <div class="personnelDashboard__title">Company corporation</div>
        <div class="personnelDashboard__section">
          <div class="personnelDashboard__section-title">Teammates:</div>
          <div class="personnelDashboard__section-content">
            <div class="personnelDashboard__add-members">
              <div class="personnelDashboard__add-members-tabs js-tabBox">
                <div class="personnelDashboard__add-members-tabs-nav">
                  <div
                    class="personnelDashboard__add-members-tabs-nav-item js-tabNavItem"
                    @click="tabActivate"
                  >
                    FlyJets member
                  </div>
                  <div
                    class="personnelDashboard__add-members-tabs-nav-item js-tabNavItem"
                    @click="tabActivate"
                  >
                    Invite by email
                  </div>
                </div>
                <div class="personnelDashboard__add-members-tabs-content">
                  <div
                    class="personnelDashboard__add-members-tabs-content-item js-tabContentItem"
                    data-id="FlyJets-member">
                    <!-- add form class "error" for show form__field-error -->
                    <!-- add form class "success" for show form__field-success -->
                    <form
                      class="form form--addMembers js-ajaxForm"
                      :class="{'loading' : addEmailLoading}"
                      @submit.prevent="submitAddMember"
                      id="addMemberProviderForm"
                    >
                      <label class="form__label form__label--text">
                        <div class="form__field-wrapper">
                          <input
                            class="form__field"
                            type="email"
                            v-model="addEmail"
                            placeholder="Add user"
                            autocomplete="off"
                            :disabled="addEmailLoading"
                          />
                          <span class="form__field-error"><SvgIcon name="warning"/> 'not found!'</span>
                          <span class="form__field-success"><SvgIcon name="checked-green"/> 'found!'</span>
                        </div>
                        <span
                          v-if="$v.addEmail.$dirty && !$v.addEmail.required"
                          class="form__field-invalid form__field-invalid--right-side"
                        >
                          This field is required
                        </span>
                        <span
                          v-if="$v.addEmail.$dirty && !$v.addEmail.email"
                          class="form__field-invalid form__field-invalid--right-side"
                        >
                        Enter a valid Email
                      </span>
                      </label>
                      <div class="form__label form__label--button">
                        <Spinner/>
                        <button class="form__button" :disabled="addEmailLoading">
                          <span class="text">Add</span>
                        </button>
                      </div>
                    </form>
                  </div>
                  <div
                    class="personnelDashboard__add-members-tabs-content-item js-tabContentItem"
                    data-id="Invite-by-email"
                  >
                    <form
                      class="form form--addMembers js-ajaxForm"
                      :class="{'loading' : inviteEmailLoading}"
                      @submit.prevent="submitInviteMember"
                      id="inviteMemberProviderForm"
                    >
                      <label class="form__label form__label--text">
                        <div class="form__field-wrapper">
                          <input
                            class="form__field"
                            type="email"
                            v-model="inviteEmail"
                            placeholder="Enter email"
                            autocomplete="off"
                            :disabled="inviteEmailLoading"
                          />
                        </div>
                        <span
                          v-if="$v.inviteEmail.$dirty && !$v.inviteEmail.required"
                          class="form__field-invalid form__field-invalid--right-side"
                        >
                        This field is required
                      </span>
                        <span
                          v-if="$v.inviteEmail.$dirty && !$v.inviteEmail.email"
                          class="form__field-invalid form__field-invalid--right-side"
                        >
                        Enter a valid Email
                      </span>
                      </label>
                      <div class="form__label form__label--button">
                        <Spinner/>
                        <button class="form__button" :disabled="inviteEmailLoading">
                          <span class="text">Add</span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="personnelDashboard__section">
          <div class="personnelDashboard__section-title">Team members</div>
          <div class="personnelDashboard__section-content">
            <PersonnelDashboardMembers :personnel="personnel"/>
          </div>
        </div>
      </div>
    </vue-scroll>
    <DashboardPreloader/>
  </div>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import Spinner from '@/components/common/Spinner/Spinner'
  import { Tabs } from '@/js/plugins/tabs'
  import PersonnelDashboardMembers from '@/components/dashboard/PersonnelDashboardMembers/PersonnelDashboardMembers'
  import DashboardPreloader from '@/views/dashboard/common/DashboardPreloader/DashboardPreloader'
  import { email, required } from 'vuelidate/lib/validators'

  export default {
    name: 'Personnel',
    data: () => ({
      tabs: new Tabs('.js-tabNavItem', '.js-tabContentItem'),
      addEmail: '',
      inviteEmail: '',
      addEmailLoading: false,
      inviteEmailLoading: false,
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
    }),
    validations: {
      addEmail: {
        email,
        required,
      },
      inviteEmail: {
        email,
        required,
      },
    },
    async created () {
      await this.$store.dispatch('getTeamMembers')

      const preloader = document.querySelector('.dashboardPreloader')
      preloader.classList.add('dashboardPreloader--hide')
    },
    computed: {
      personnel () {
        return this.$store.state.personnel.list
      },
    },
    mounted () {
      this.tabs.addActiveForMouted()
    },
    methods: {
      tabActivate (event) {
        this.tabs.addActiveForClick(event.target)
      },
      async submitAddMember () {
        if (this.$v.addEmail.$invalid) {
          this.$v.addEmail.$touch()
          return false
        }

        this.addEmailLoading = true

        const data = {
          email: this.addEmail,
        }

        await this.$store.dispatch('addTeamMember', data)
        this.addEmailLoading = false
      },
      async submitInviteMember (event) {
        if (this.$v.inviteEmail.$invalid) {
          this.$v.inviteEmail.$touch()
          return false
        }

        this.inviteEmailLoading = true

        const data = {
          email: this.inviteEmail,
        }

        await this.$store.dispatch('addTeamMember', data)
        this.inviteEmailLoading = false
      },
    },
    components: {
      SvgIcon,
      Spinner,
      PersonnelDashboardMembers,
      DashboardPreloader,
    },
  }
</script>
